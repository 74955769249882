import { UseMutateAsyncFunction, useMutation } from "@tanstack/react-query";
import { axiosInstance } from "../../../../services/axios.service";
import { getAccessToken } from "../../../../services/auth.service";

async function deleteAgent(agentId: string): Promise<void> {
  return axiosInstance.delete(`/agents/${agentId}`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
  });
}

export function useDeleteAgent(
  agentId: string,
): UseMutateAsyncFunction<void, unknown, void, unknown> {
  const { mutateAsync } = useMutation(() => deleteAgent(agentId));

  return mutateAsync;
}
