import {
  Card,
  IndexFilters,
  IndexTable,
  Link,
  Page,
  TabProps,
  Text,
  useSetIndexFiltersMode,
} from "@shopify/polaris";
import { useDebounce } from "@uidotdev/usehooks";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import React, { useCallback, useState } from "react";
import { useAgents } from "./hooks/useAgents";
import { Agent } from "../../../types/common.types";
import { formatDate, formatMoney } from "../../../helpers/helpers";
import NewAgentModal from "./NewAgentModal";

export default function AgentsPage() {
  const { mode, setMode } = useSetIndexFiltersMode();

  const [selected, setSelected] = useState(0);
  const [agentsStatus, setAgentsStatus] = useState("active");
  const [modalActive, setModalActive] = useState(false);
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 1000);

  const columns = [
    "Full name",
    "Email",
    "Salary",
    "Started at",
    "Last payment at",
  ];
  const tabs: TabProps[] = [
    {
      id: "active",
      content: "Active",
      isLocked: true,
    },
    {
      id: "inactive",
      content: "Inactive",
    },
    {
      id: "all",
      content: "All",
    },
  ];

  console.log(debouncedQuery);

  const { minPage, maxPage, isFetching, page, setPage, agents, totalAmount } =
    useAgents(15, agentsStatus, debouncedQuery);

  const handleTabChange = useCallback((value: number) => {
    setSelected(value);
    setAgentsStatus(tabs[value].id);
  }, []);

  const rowMarkup = agents.map((agent: Agent, index) => (
    <IndexTable.Row id={agent.id as string} key={agent.id} position={index}>
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link dataPrimaryLink url={`/admin/agents/${agent.id}`}>
          <Text fontWeight="bold" as="span">
            {agent.first_name} {agent.last_name}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{agent.email}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(agent.salary)}</IndexTable.Cell>
      <IndexTable.Cell>
        {agent.started_at ? formatDate(agent.started_at, true) : "-"}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {agent.last_payment_at ? formatDate(agent.last_payment_at, true) : "-"}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page
      fullWidth
      title="Agents"
      primaryAction={{
        content: "Add",
        onAction: () => {
          setModalActive(true);
        },
      }}
    >
      <Card padding="0">
        <IndexFilters
          queryValue={query}
          onQueryChange={setQuery}
          onQueryClear={() => setQuery("")}
          cancelAction={{
            onAction: () => {},
            disabled: false,
            loading: false,
          }}
          tabs={tabs}
          selected={selected}
          onSelect={handleTabChange}
          filters={[]}
          onClearAll={() => {}}
          mode={mode}
          setMode={setMode}
          hideFilters
          canCreateNewView={false}
        />
        <IndexTable
          resourceName={{
            singular: "Agent",
            plural: "Agents",
          }}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          itemCount={agents.length}
          loading={isFetching}
          selectable={false}
          pagination={{
            hasPrevious: page > minPage,
            hasNext: maxPage > 1 && page < maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          <IndexTable.Row rowType="subheader" position={0} id="total">
            <IndexTable.Cell>Total</IndexTable.Cell>
            <IndexTable.Cell />
            <IndexTable.Cell>{formatMoney(totalAmount)}</IndexTable.Cell>
            <IndexTable.Cell />
            <IndexTable.Cell />
          </IndexTable.Row>
          {rowMarkup}
        </IndexTable>
      </Card>
      <NewAgentModal active={modalActive} setActive={setModalActive} />
    </Page>
  );
}
