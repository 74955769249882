import { useNavigate, useParams } from "react-router-dom";
import {
  Page,
  SkeletonPage,
  Layout,
  SkeletonBodyText,
  TextContainer,
  SkeletonDisplayText,
  Card,
} from "@shopify/polaris";
import { useContext, useState } from "react";
import { Agent } from "../../../types/common.types";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { useAgent } from "./hooks/useAgent";
import AgentPersonalDetails from "./components/AgentPersonalDetails";
import AgentPayments from "./components/AgentPayments/AgentPayments";
import AgentInternalDetails from "./components/AgentInternalDetails";
import { useDeleteAgent } from "./hooks/useDeleteAgent";

export default function AgentPage() {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);

  const { agentId } = useParams();
  const navigate = useNavigate();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { agent, isLoading, isError } = useAgent(agentId as string);

  const deleteAgent = useDeleteAgent(agentId as string);

  const handleDeleteAgent = async () => {
    setDeleteLoading(false);

    try {
      await deleteAgent();

      onShowToast("Agent Deleted", false);

      navigate("/admin/agents");
    } catch (e: any) {
      onShowToast("Something went wrong", true);

      console.error(e.response.data);
    }
  };

  if (isError) {
    navigate("/admin/agents");
  }

  if (isLoading)
    return (
      <SkeletonPage fullWidth>
        <Layout>
          <Layout.Section>
            <Card>
              <SkeletonBodyText />
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText />
              </TextContainer>
            </Card>
          </Layout.Section>
        </Layout>
      </SkeletonPage>
    );

  return (
    <Page
      fullWidth
      title={`${agent?.first_name} ${agent?.last_name}`}
      backAction={{
        onAction: () => navigate("/admin/agents"),
      }}
      primaryAction={{
        content: "Delete",
        destructive: true,
        onAction: handleDeleteAgent,
        loading: deleteLoading,
      }}
    >
      <Layout>
        <Layout.Section variant="oneHalf">
          <AgentPersonalDetails agent={agent as Agent} />
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <AgentInternalDetails agent={agent as Agent} />
        </Layout.Section>
        <Layout.Section variant="fullWidth">
          <AgentPayments agentId={(agent as Agent).id as string} />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
